<script setup>
const props = defineProps(["filter", "filtersStore"]);

const selectedSlug = ref(props.filtersStore.selected_filters[props.filter.slug]?.value?.slug || '');

const updateValue = (filter, row) => {
  props.filtersStore.updateValue(filter, row);
  if (props.filtersStore.selected_filters[props.filter.slug].value) {
    selectedSlug.value = props.filtersStore.selected_filters[props.filter.slug].value.slug
  }
};

watch(
  () => props.filtersStore.selected_filters[props.filter.slug],
  () => {
    selectedSlug.value = props.filtersStore.selected_filters[props.filter.slug].value.slug || '';
  }
);
</script>

<template>
  <div class="border-b my-3">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ filter.name }}</h3>
    <div class="w-full flex gap-2 snap-x overflow-x-auto my-3">
      <div class="snap-start  shrink-0 relative" v-for="item in filter.data_source">
        <button @click="updateValue(filter, item)" class="rounded-2xl p-3 block w-24"
          :class="{ [item.slug == selectedSlug ? 'active-type' : 'bg-white']: true }">
          <div class="flex flex-col justify-center items-center">
            <i :class="item.icon" class="h-6 text-indigo-900" v-if="item.icon"></i>
            <h4 class="text-sm" :class="{ [item.icon ? 'mt-3' : '']: true }">{{
              item.value }}</h4>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>